import React, { useState } from "react";
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  CloudSyncOutlined,
  HistoryOutlined,
  MessageOutlined,
  FileImageOutlined,
  SafetyCertificateOutlined,
  SolutionOutlined,
  CloudUploadOutlined,
  FileProtectOutlined,
  BarChartOutlined
} from "@ant-design/icons";
import { Menu, Layout } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { adminRole } from "../DataLayer/Constants";

const { Sider } = Layout;

const items = [
  { key: "1", icon: UserOutlined, label: "Groups", path: "/static/Groups" },
  {
    key: "2",
    icon: VideoCameraOutlined,
    label: "Applications",
    path: "/static/Applications",
  },
  {
    key: "3",
    icon: UploadOutlined,
    label: "Configurations",
    path: "/static/Configurations",
  },
  {
    key: "4",
    icon: FileImageOutlined,
    label: "MyApplications",
    path: "/static/myApplications",
  },
  {
    key: "5",
    icon: MessageOutlined,
    label: "GenieConfigurations",
    path: [
      "/static/GenieConfigurations",
      "/static/GenieConfigurations/AddOrUpdate",
    ],
  },
  {
    key: "6",
    icon: HistoryOutlined,
    label: "Logs",
    path: "/static/Logs",
  },
  {
    key: "7",
    icon: CloudSyncOutlined,
    label: "DataSync",
    path: "/static/dataSync",
  },
  {
    key: "8",
    icon: BarChartOutlined,
    label: "UserResponseDashboard",
    path: "/static/userResponseDashboard",
  },
  {
    key: "9",
    icon: SafetyCertificateOutlined,
    label: "Guidelines Configuration",
    path: "/static/guideLineConfiguration",
  },
  {
    key: "10",
    icon: SolutionOutlined,
    label: "Policy Configuration",
    path: "/static/policyConfiguration",
  },
  {
    key: "11",
    icon: CloudUploadOutlined,
    label: "Process Configuration",
    path: "/static/processConfiguration",
  },
  {
    key: "12",
    icon: FileProtectOutlined,
    label: "GuideLines",
    path: "/static/guideLines",
  },
  {
    key: "13",
    icon: FileProtectOutlined,
    label: "Policy",
    path: "/static/policy",
  },
  {
    key: "14",
    icon: FileProtectOutlined,
    label: "Process",
    path: "/static/process",
  },
].map((item) => ({
  key: item.key,
  icon: React.createElement(item.icon),
  label: item.label,
  path: item.path,
}));

const userItems = [
  {
    key: "4",
    icon: UserOutlined,
    label: "MyApplications",
    path: "/static/myApplications",
  },
  {
    key: "12",
    icon: FileProtectOutlined,
    label: "GuideLines",
    path: "/static/guideLines",
  },
  {
    key: "13",
    icon: FileProtectOutlined,
    label: "Policy",
    path: "/static/policy",
  },
  {
    key: "14",
    icon: FileProtectOutlined,
    label: "Process",
    path: "/static/process",
  },
].map((item) => ({
  key: item.key,
  icon: React.createElement(item.icon),
  label: item.label,
  path: item.path,
}));

const Sidebar = ({ onCollapse, userRolesData }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCollapse = (collapsed) => {
    setCollapsed(collapsed);
    onCollapse(collapsed);
  };

  const handleMenuClick = (e) => {
    const item = items.find((item) => item.key === e.key);
    if (item) {
      Array?.isArray(item.path) ? navigate(item.path[0]) : navigate(item.path);
    }
  };

  const selectedKey =
    items.find((item) =>
      Array?.isArray(item.path)
        ? item?.path?.includes(location?.pathname)
        : item.path === location.pathname
    )?.key || "1";

  return (
    <Sider
      breakpoint="lg"
      collapsed={collapsed}
      collapsible
      width={240}
      collapsedWidth={80}
      onCollapse={handleCollapse}
      style={{
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 64,
        bottom: 0,
        backgroundColor: "#211261",
        overflow: "auto",
        scrollbarWidth: "thin",
        scrollbarGutter: "stable",
        // width: collapsed ? 80 : 250,
        // minWidth: collapsed ? 80 : 250,
      }}
    >
      <div style={{ height: "85%", overflowY: "auto", scrollbarWidth: "none" }}>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]} // Set the selected key
          items={
            userRolesData?.userRole?.filter(
              (f) =>
                f["odataType"]?.includes("graph.directoryRole") &&
                f?.displayName === adminRole
            )?.length > 0
              ? items
              : userItems
          }
          onClick={handleMenuClick}
          style={{ backgroundColor: "#211261" }}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
